import { MergeClientsValues } from 'pages/clients/merge/form/types'
import { Client } from 'types/masterData/clients'
import { prepareIdBasedPills } from 'utils/merge/prepareIdBasedPills'
import { prepareLogo } from 'utils/merge/prepareLogo'
import { prepareParentId } from 'utils/merge/prepareParentId'
import { preparePillsLike } from 'utils/merge/preparePillsLike'

export const convertToTwoEntityValues = ([entry1, entry2]: Client[]): MergeClientsValues => {
  const logo = prepareLogo([entry1, entry2])

  const aliases = preparePillsLike({ entries: [entry1, entry2], field: 'aliases' })

  const parentId = prepareParentId<Client>({
    entries: [entry1, entry2],
    extractFn: entry =>
      entry.parent
        ? {
            id: entry.parent?.id!,
            label: entry.parent?.name!,
          }
        : null,
  })

  const children = prepareIdBasedPills({
    entries: [entry1, entry2],
    extractFn: entry =>
      entry.children.map(item => ({
        id: item.id,
        label: item.name,
      })),
    field: 'children',
  })

  const brands = prepareIdBasedPills({
    entries: [entry1, entry2],
    extractFn: entry =>
      entry.brands.map(item => ({
        id: item.id,
        label: item.name,
      })),
    field: 'brands',
  })

  const tableRowDataFields = [...logo, ...aliases, ...parentId, ...children, ...brands]

  return {
    fields: {
      name: [entry1.name, entry2.name],
    },
    tableRowDataFields,
  }
}
