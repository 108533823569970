import { WppActionButton, WppButton, WppIconTrash } from '@platform-ui-kit/components-library-react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useCreateMarketApi } from 'api/masterData/mutations/useCreateMarketApi'
import { useEditMarketApi } from 'api/masterData/mutations/useEditMarketApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useUpsertEntityContext } from 'pages/components/upsertEntityWrapper/components/UpsertEntityContext'
import { showDeleteMarketModal } from 'pages/markets/deleteMarketModal/DeleteMarketModal'
import { convertFormToFormDto } from 'pages/markets/upsert/utils'
import { handleReloadMarketsListTable } from 'pages/markets/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { Market, MarketForm } from 'types/masterData/markets'
import { routesManager } from 'utils/routesManager'

interface Props {
  market: Market
  form: UseFormReturn<MarketForm>
}

export const ActionButtons = ({ market, form }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { enqueueToast } = useToast()
  const {
    contextState: { nonCurrentVersionSelected },
    handleSelectVersion,
  } = useUpsertEntityContext()
  const { mutateAsync: handleCreate } = useCreateMarketApi()
  const { mutateAsync: handleEdit } = useEditMarketApi()
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = form

  const onError = () => {
    enqueueToast({
      message: t('common.errors.general'),
      type: 'error',
    })
  }

  const onCreateSubmit = async (values: MarketForm) => {
    try {
      const { data: createdMarket } = await handleCreate({
        market: convertFormToFormDto(values),
      })
      navigate(routesManager.masterData.markets.update.getURL({ entryId: createdMarket?.id }))

      handleSelectVersion({ version: null })
      await Promise.all([
        handleReloadMarketsListTable(market?.id),
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_ENTITY_CHANGE_HISTORY] }),
      ])

      enqueueToast({
        message: t('common.toasts.create', { entity: t('common.master_data.entities.market') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const onEditSubmit = async (values: MarketForm) => {
    try {
      await handleEdit({
        id: market!.id,
        market: convertFormToFormDto(values),
      })

      handleSelectVersion({ version: null })
      await Promise.all([
        handleReloadMarketsListTable(market!.id),
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.MASTER_DATA_ENTITY_CHANGE_HISTORY] }),
      ])

      enqueueToast({
        message: t('common.toasts.update', { entity: t('common.master_data.entities.market') }),
        type: 'success',
      })
    } catch {
      onError()
    }
  }

  const handleDelete = () => {
    showDeleteMarketModal({
      market: market!,
      onAfterDelete: () => {
        navigate(routesManager.masterData.markets.root.getURL())
      },
    })
  }

  return (
    <>
      {market && (
        <WppActionButton variant="destructive" disabled={nonCurrentVersionSelected} onClick={handleDelete}>
          <WppIconTrash slot="icon-start" />
          {t('common.delete')}
        </WppActionButton>
      )}
      <WppButton variant="secondary" disabled={!isDirty || nonCurrentVersionSelected} onClick={() => reset()}>
        {t('common.reset')}
      </WppButton>
      <WppButton
        loading={isSubmitting}
        variant="primary"
        disabled={nonCurrentVersionSelected}
        onClick={() => handleSubmit(market ? onEditSubmit : onCreateSubmit)()}
      >
        {t('common.save')}
      </WppButton>
    </>
  )
}
