import { convertToForm as convertToAgenciesForm } from 'pages/agencies/upsert/utils'
import { convertToForm as convertToBrandsForm } from 'pages/brands/upsert/utils'
import { convertToForm as convertToClientsForm } from 'pages/clients/upsert/utils'
import { convertToForm as convertToGeographiesForm } from 'pages/geographies/upsert/utils'
import { convertToForm as convertToIndustriesForm } from 'pages/industries/upsert/utils'
import { convertToForm as convertToMarketsForm } from 'pages/markets/upsert/utils'
import { ChangeHistory } from 'types/masterData/changeHistory'
import { EntityFormAllFields, RouteModules } from 'types/masterData/utils'
import { snakeToCamelCase } from 'utils/common'

const mapChangedFieldToFormField: Record<string, string> = {
  logoImageId: 'logoOriginal',
}

export const mapChangeHistoryEntityItems = (items: ChangeHistory<EntityFormAllFields>[]) =>
  items.map(item => ({
    ...item,
    fields: item.fields.map(item => {
      const mappedField = snakeToCamelCase(item)
      return mapChangedFieldToFormField[mappedField] || mappedField
    }),
  }))

export const convertToFormObj = {
  agencies: convertToAgenciesForm,
  brands: convertToBrandsForm,
  clients: convertToClientsForm,
  geographies: convertToGeographiesForm,
  industries: convertToIndustriesForm,
  markets: convertToMarketsForm,
} as const satisfies Record<RouteModules, unknown>

export const getUpdatedByLabel = (updatedBy: ChangeHistory['updatedBy'], versionNumber?: number) =>
  updatedBy.username || updatedBy.email || versionNumber?.toString()
