import { MergeMarketsValues } from 'pages/markets/merge/form/types'
import { Market } from 'types/masterData/markets'
import { prepareIdBasedPills } from 'utils/merge/prepareIdBasedPills'
import { prepareLogo } from 'utils/merge/prepareLogo'
import { prepareParentId } from 'utils/merge/prepareParentId'
import { preparePillsLike } from 'utils/merge/preparePillsLike'
import { prepareTextLike } from 'utils/merge/prepareTextLike'

export const convertToTwoEntityValues = ([entry1, entry2]: Market[]): MergeMarketsValues => {
  const logo = prepareLogo([entry1, entry2])

  const aliases = preparePillsLike({ entries: [entry1, entry2], field: 'aliases' })

  const isoAlpha2 = prepareTextLike({ entries: [entry1, entry2], field: 'isoAlpha2' })

  const isoAlpha3 = prepareTextLike({ entries: [entry1, entry2], field: 'isoAlpha3' })

  const geographyIds = prepareIdBasedPills({
    entries: [entry1, entry2],
    extractFn: entry =>
      entry.geographies.map(item => ({
        id: item.id,
        label: item.name,
      })),
    field: 'geographyIds',
  })

  const parentId = prepareParentId<Market>({
    entries: [entry1, entry2],
    extractFn: entry =>
      entry.parent
        ? {
            id: entry.parent?.id!,
            label: entry.parent?.name!,
          }
        : null,
  })

  const tableRowDataFields = [...logo, ...aliases, ...parentId, ...isoAlpha2, ...isoAlpha3, ...geographyIds]

  return {
    fields: {
      name: [entry1.name, entry2.name],
    },
    tableRowDataFields,
  }
}
