import { MergeableEntity } from 'types/masterData/mergeEntities'
import { EntityAllFields, EntityFormAllFields } from 'types/masterData/utils'

export const textLikeFields = [
  'description',
  'shortDescription',
  'navigatorUrl',
  'serviceName',
  'isoAlpha2',
  'isoAlpha3',
] as const satisfies (keyof EntityAllFields)[]
export type TextLikeFields = typeof textLikeFields
export type TextLikeField = TextLikeFields[number]

interface Props<T extends EntityAllFields, F extends TextLikeField> {
  entries: T[]
  field: F
}

export const prepareTextLike = <T extends EntityAllFields, F extends TextLikeField>({
  entries: [entry1, entry2],
  field,
}: Props<T, F>) => {
  if (!entry1[field] && !entry2[field]) return []

  const isSameValue = entry1[field] === entry2[field]

  return [
    {
      id: field as F,
      index: 0,
      entry1: {
        data: entry1[field] as string,
        isSameValue,
        entryName: entry1?.name! || entry1?.displayName!,
      },
      entry2: {
        data: entry2[field] as string,
        isSameValue,
        entryName: entry2?.name! || entry2?.displayName!,
      },
    } as const satisfies MergeableEntity<EntityFormAllFields>,
  ]
}
