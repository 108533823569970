import { RowSelectedEvent } from 'ag-grid-community'
import { useSetState } from 'react-use'

import { useStableCallback } from 'hooks/useStableCallback'
import { EntityCommonFields } from 'types/masterData/utils'

interface CommonState<T extends EntityCommonFields> {
  selectedRows: T['id'][]
}

interface Props<T extends EntityCommonFields> {
  setState: ReturnType<typeof useSetState<CommonState<T>>>[1]
}

export const MAX_SELECTED_ROWS = 2
export const useOnRowSelected = <T extends EntityCommonFields>({ setState }: Props<T>) =>
  useStableCallback(({ data: changedItem, source }: RowSelectedEvent<T>) => {
    if (source === 'checkboxSelected') {
      setState(({ selectedRows }) => ({
        selectedRows: selectedRows.includes(changedItem!.id)
          ? selectedRows.filter(item => item !== changedItem!.id)
          : [...selectedRows, changedItem!.id],
      }))
    }
  })
