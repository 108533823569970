import { MayBeNull } from '@wpp-open/core'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { UseQueryReturnType } from 'api/common/types'
import { useFetchClientApi } from 'api/masterData/queries/useFetchClientApi'
import { Flex } from 'components/common/flex/Flex'
import { FormClientsAutocomplete } from 'components/form/formAutocomplete/FormClientsAutocomplete'
import { FormInputAutocomplete } from 'components/form/formAutocomplete/FormInputAutocomplete'
import { FormReadonlyPills } from 'components/form/formReadonlyPills/FormReadonlyPills'
import { FormTextInput } from 'components/form/formTextInput/FormTextInput'
import { ManageMasterDataLogoUpload } from 'components/logoUpload/ManageMasterDataLogoUpload'
import { useForm } from 'hooks/form/useForm'
import { ActionButtons } from 'pages/clients/upsert/components/ActionButtons'
import { useClientValidationScheme, convertToForm, defaultValues } from 'pages/clients/upsert/utils'
import { EntityDetailsCards } from 'pages/components/entityDetailsCards/EntityDetailsCards'
import { useUpsertEntityContext } from 'pages/components/upsertEntityWrapper/components/UpsertEntityContext'
import { UpsertEntityWrapper } from 'pages/components/upsertEntityWrapper/UpsertEntityWrapper'
import { Client } from 'types/masterData/clients'

export const UpsertClient = () => {
  const { t } = useTranslation()
  const { entryId } = useParams()
  const { state } = useLocation()
  const clientState = state?.data as MayBeNull<Client>

  const query = useFetchClientApi({
    params: { entryId: entryId! },
    enabled: !!entryId,
  })
  const client = query.data || clientState

  const form = useForm({
    defaultValues: client ? convertToForm(client) : defaultValues,
    validationSchema: useClientValidationScheme(),
  })

  const {
    contextState: { selectedVersionFields, nonCurrentVersionSelected },
  } = useUpsertEntityContext()

  const shouldDisableFields = nonCurrentVersionSelected || query.isLoading

  return (
    <FormProvider {...form}>
      <UpsertEntityWrapper
        query={query as UseQueryReturnType}
        title={t(`common.${client ? 'update' : 'create'}_entity`, { entity: t('common.master_data.entities.client') })}
        actionButtons={<ActionButtons form={form} client={client!} />}
        rightCards={client && <EntityDetailsCards entity={client} />}
      >
        <Flex direction="column" gap={24}>
          <ManageMasterDataLogoUpload
            name={clientState?.name || ''}
            disabled={shouldDisableFields}
            tagMessage={selectedVersionFields.logoOriginal}
          />
          <FormTextInput
            tagMessage={selectedVersionFields.name}
            disabled={shouldDisableFields}
            name="name"
            labelConfig={{
              text: t('master_data.fields.name.label'),
              description: t('master_data.fields.name.hint'),
            }}
            required
          />
          <FormInputAutocomplete
            disabled={shouldDisableFields}
            tagMessage={selectedVersionFields.aliases}
            labelConfig={{
              text: t('master_data.fields.aliases.label'),
              description: t('master_data.fields.aliases.hint'),
            }}
            name="aliases"
          />
          <FormClientsAutocomplete
            disabled={shouldDisableFields}
            tagMessage={selectedVersionFields.parentId}
            name="parentId"
            multiple={false}
            labelConfig={{
              text: t('master_data.fields.parent.label', { entity: t('common.master_data.lowercase_entities.client') }),
              description: t('master_data.fields.parent.hint', {
                entity: t('common.master_data.lowercase_entities.client'),
                entities: t('common.master_data.lowercase_entities.client', { count: 2 }),
              }),
            }}
          />
          <FormReadonlyPills
            disabled={shouldDisableFields}
            tagMessage={selectedVersionFields.children}
            navigationTo="clients"
            labelConfig={{
              text: t('master_data.fields.subsidiaries.label'),
              description: t('master_data.fields.subsidiaries.hint', {
                entity: t('common.master_data.entities.client'),
                entities: t('common.master_data.entities.client', { count: 2 }),
              }),
            }}
            name="children"
            getItemLabel={item => item.name}
          />
          <FormReadonlyPills
            disabled={shouldDisableFields}
            tagMessage={selectedVersionFields.brands}
            navigationTo="brands"
            labelConfig={{
              text: t('master_data.fields.brands.label'),
              description: t('master_data.fields.brands.hint', {
                entity: t('common.master_data.entities.client'),
              }),
            }}
            name="brands"
            getItemLabel={item => item.name}
          />
        </Flex>
      </UpsertEntityWrapper>
    </FormProvider>
  )
}
