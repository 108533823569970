import { MergeClientsForm } from 'pages/clients/merge/form/types'

export const defaultFormValues: MergeClientsForm = {
  name: '',
  isNewName: false,
  newName: '',
  logoThumbnail: null,
  aliases: [],
  parentId: null,
  children: [],
  brands: [],
}
